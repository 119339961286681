import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Checkbox, Divider, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, Tab, TextField, Tooltip, Typography } from '@mui/material';
import { DataGridPro, GridCellEditCommitParams, GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { Asset, FIConfig, FeasibilityInstance, FinancialModel, Project, ProjectMeta } from '../../../../models/project.model';
import { getFeasibilityInstances, getFinancialModels, getProject, putProject, uploadThumbnail } from '../../../../services/project.service';

import { metaKeyFriendly } from '../../../../helpers/helper';
import { PROJECT_META_KEYS, PROJECT_STATUS } from '../../../../constants/project';
import { getAccounts } from '../../../../services/account.service';
import { Account, AccountProject } from '../../../../models/account.model';
import { PatchModel } from '../../../../models/path.model';
import { patchAsset } from '../../../../services/asset.service';
import { ConfirmModal } from '../../../shared/ui/modals/ConfirmModal';
import { StatusEdit } from '../../../shared/ui/grid/StatusEdit';
import TimeAgo from 'timeago-react';
import CopyToClipboardButton from '../../../shared/ui/CopyToClipboardButton';
import { FeasibilityInstances } from './feasibility/FeasibilityInstances';
import UploadIcon from '@mui/icons-material/Upload';
import { UploadAsset } from './assets/UploadAsset';
import { FinancialsModels } from './financial/FinancialModels';
import { getFoursquareToken } from '../../../../services/token.service';
import { FoursquareToken } from '../../../../models/token.model';
import PreviewIcon from '@mui/icons-material/Preview';
import { AuthorAvatar } from '../../../shared/ui/AuthorAvatar';
import { useSnackBar } from '../../../../contexts/SnackbarContext';
import { ScenarioEdit } from '../users/grid/ScenarioEdit';
import { ProjectAssets } from './assets/ProjectAssets';
import { Log } from '../../../../models/log.model';
import { getProjectLogs } from '../../../../services/logs.service';
import { Logs } from '../../Logs';
import { authStatus } from '../../../../services/auth.service';

export function ProjectSingle() {
   const isAdmin = ['ADMIN'].some((role) => authStatus().content?.roles.includes(role));
   const { id } = useParams();
   const location = useLocation();
   const navigate = useNavigate();
   const queryParams = new URLSearchParams(location.search);
   const tabId = queryParams.get('tab');
   const [project, setProject] = useState<Project>();
   const [loading, setLoading] = useState<boolean>(false);
   const [accounts, setAccounts] = useState<Account[]>([]);
   const [hasChanged, setHasChanged] = useState<boolean>(false);
   const [tabIndex, setTabIndex] = useState(isAdmin ? (tabId && ['1', '2', '3', '4'].includes(tabId) ? tabId : '3') : '2');

   const [file, setFile] = useState(null);
   const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);

   const [feasibilityInstances, setFeasibilityInstances] = useState<FeasibilityInstance[]>([]);
   const [instanceForFinancial, setInstanceForFinancial] = useState<FeasibilityInstance | undefined>();
   const [financialModels, setFinancialModels] = useState<FinancialModel[]>([]);
   const [foursquareToken, setFoursquareToken] = useState<FoursquareToken | null>(null);
   const [gridHeight, setGridHeight] = useState<number>(0);
   const [gridInfoHeight, setGridInfoHeight] = useState<number>(0);
   const [gridAssetsHeight, setGridAssetsHeight] = useState<number>(0);
   const [logs, setLogs] = useState<Log[] | null>(null);

   useEffect(() => {
      if (!project) return;
      const fetchLogs = async () => {
         try {
            const fetchedLogs = await getProjectLogs(project.id);
            setLogs(fetchedLogs ?? []);
         } catch (error) {
            console.error(error);
            setLogs([]);
         }
      };

      fetchLogs();
   }, [project]);
   const snackbar = useSnackBar();

   useEffect(() => {
      if (!project?.id) return;
      function updateGridHeight() {
         const topHeight = document.getElementById('admin-top')?.clientHeight;
         setGridHeight(window.innerHeight - (topHeight ?? 0));
      }

      updateGridHeight();
      window.addEventListener('resize', updateGridHeight);
      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, [project?.id]);

   useEffect(() => {
      const adminAssetsTopHeight = document.getElementById('admin-assets-top')?.clientHeight;
      setGridAssetsHeight(gridHeight - (adminAssetsTopHeight ?? 0) - 53);
      setGridInfoHeight(gridHeight - 53);
   }, [gridHeight, tabIndex]);

   useEffect(() => {
      retrieveProject();
      retrieveAccounts();
      retrieveFoursquareToken();
   }, []);

   useEffect(() => {
      if (!project) return;
      retrieveFeasibilitiesInstances();
      retrieveFinancialModels();

      const intervalId = setInterval(() => {
         retrieveFeasibilitiesInstances();
         retrieveFinancialModels();
      }, 8000);

      return () => clearInterval(intervalId);
   }, [project]);

   useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('tab', tabIndex);
      window.history.replaceState(null, '', `?${queryParams.toString()}`);
   }, [tabIndex]);

   useEffect(() => {
      if (instanceForFinancial) setTabIndex('2');
   });

   async function retrieveFeasibilitiesInstances() {
      if (!project) return;
      try {
         const response = await getFeasibilityInstances(project.id);
         if (response.feasibilityInstances.length > 0) {
            var list: FeasibilityInstance[] = [];
            response.feasibilityInstances.forEach((elem: any) => {
               list.push({
                  boundaryType: elem.boundaryType,
                  boundaryName: elem.boundaryName,
                  boundaryContent: elem.boundaryContent,
                  configJSON: JSON.parse(elem.configJSON) as FIConfig,
                  createdAt: elem.createdAt,
                  instanceId: elem.instanceId,
                  isActive: elem.isActive,
                  logs: elem.logs,
                  status: elem.status,
                  notes: elem.notes,
                  user: elem.user,
               });
            });
            setFeasibilityInstances(list);
         }
      } catch (error) {
         console.log(error);
      }
   }

   const retrieveFinancialModels = async () => {
      if (!project) return;
      try {
         var response = await getFinancialModels(project.id);
         if (!response.error && response.financialModels) {
            setFinancialModels(response.financialModels);
         } else {
         }
      } catch (e) { }
   };

   async function retrieveAccounts() {
      setLoading(true);
      try {
         var response = await getAccounts(
            {
               page: 0,
               rowsPerPage: 100,
               search: '',
               orderBy: 'name',
               asc: true,
            },
            false
         );
         if (!response.error) {
            setAccounts(response.accounts);
         } else {
         }
         setLoading(false);
      } catch (e) {
         setLoading(false);
      }
   }

   async function retrieveProject() {
      if (!id) return;
      setLoading(true);
      try {
         var response = await getProject(id, true);
         if (!response.error) {
            setProject(response.project);
         } else {
         }
         setLoading(false);
      } catch (e) {
         setLoading(false);
      }
   }

   async function updateProject(e: any) {
      e.preventDefault();
      if (!project) return;

      setLoading(true);
      try {
         if (file) await uploadNewThumbnail();

         var updatedProject = project;
         // if (!project.projectMeta.find((meta: ProjectMeta) => meta.key === 'map_id')?.value) {
         //    updatedProject = { ...updatedProject, isActive: false };
         //    setProject(updatedProject);
         // }

         const response = await putProject(updatedProject);
         if (!response.error) {
            snackbar.success('Project successfully updated.');
            setProject({ ...project, thumbnail: response.newThumbnail ?? project.thumbnail, updatedAt: new Date() });
            setHasChanged(false);
         } else {
            snackbar.error('Error while updating the user.');
         }
         setLoading(false);
      } catch (e) {
         setLoading(false);
      }
   }

   const handleFileChange = (event: any) => {
      const selectedFile = event.target.files[0];

      if (selectedFile && selectedFile.type.startsWith('image/')) {
         setFile(selectedFile);
         const reader = new FileReader();
         reader.readAsDataURL(selectedFile);
         reader.onloadend = () => {
            setPreview(reader.result);
         };
      }
   };

   const uploadNewThumbnail = async () => {
      if (!file || !project) return;
      const formData = new FormData();
      formData.append('file', file);
      try {
         const response = await uploadThumbnail(project.id, formData);
         if (!response.error) {
            // setProject({ ...project, thumbnail: response.thumbnail });
            // setFile(null);
            // setPreview(null);
         } else {
         }
      } catch (err) {
         console.error(err);
      }
   };

   const retrieveFoursquareToken = async () => {
      try {
         const response = await getFoursquareToken();
         if (!response.error && response.token) {
            setFoursquareToken({
               token: response.token.token,
               refreshToken: response.token.refreshToken,
            });
         } else {
            setFoursquareToken(null);
         }
      } catch (err) {
         console.error(err);
      }
   };

   return (
      <div>
         <div id="admin-top" className="flex row between items-start admin-top">
            <div>
               <Button onClick={() => navigate('/admin/projects')} size="medium" variant="text" startIcon={<KeyboardBackspaceIcon />}>
                  {' '}
                  Projects{' '}
               </Button>
               <div className="flex items-center">
                  <div className="flex column mt-2">
                     <Typography className="ml-3" sx={{ lineHeight: '35px' }} variant="h6" color="primary">
                        {project?.id !== undefined ? project?.name : 'Projects'}
                     </Typography>
                     <Divider />
                     {project?.id !== undefined && (
                        <div>
                           <div className="ml-3 mt-2 gap-10 flex column">
                              <div className="flex row items-center">
                                 <p>{project.id}</p>
                                 <CopyToClipboardButton value={project.id} />
                              </div>
                              <div className="flex items-center gap">
                                 <div className="flex column">
                                    <p>Creation: {new Date(project.createdAt?.toString() ?? '').toLocaleString() ?? 'null'}</p>
                                    <p>Last Modification: {new Date(project.updatedAt?.toString() ?? '').toLocaleString() ?? 'null'}</p>
                                 </div>
                                 <AuthorAvatar firstname={project.user?.firstname} lastname={project.user?.lastname} />
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
            </div>
            <Link to={`/project/${project?.id}/`} className="flex items-center gap-small">
               <PreviewIcon /> {!project?.isActive ? 'Preview' : 'View'} Project
            </Link>
         </div>
         {project?.id !== undefined && (
            <div className="flex gap-small between w-100" style={{ height: gridHeight }}>
               <Box
                  sx={{
                     border: 1,
                     borderColor: 'divider',
                     width: '100%',
                     overflow: 'auto',
                  }}>
                  <TabContext value={tabIndex}>
                     <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                           onChange={(event: React.SyntheticEvent, newValue: string) => {
                              setTabIndex(newValue);
                           }}>
                           {/* {isAdmin && <Tab label="DMA Instances" value="1" />} commented out by SRoy*/}
                           <Tab label="DMA Instances" value="1" />
                           <Tab label="Financial Scenarios" value="2" />
                           {/* {isAdmin && <Tab label="Info" value="3" />} commented out by SRoy*/}
                           <Tab label="Info" value="3" />
                           {/* {isAdmin && <Tab label="Assets" value="4" />} commented out by SRoy*/}
                           <Tab label="Assets" value="4" />
                           {/* {isAdmin && <Tab label="Logs" value="5" />} commented out by SRoy*/}
                           <Tab label="Logs" value="5" />
                        </TabList>
                     </Box>
                     {project && (
                        <div className="w-100" style={{ padding: '0' }}>
                           <TabPanel value="1">
                              <FeasibilityInstances
                                 parentHeight={gridHeight}
                                 project={project}
                                 feasibilityInstances={feasibilityInstances}
                                 setFeasibilityInstances={setFeasibilityInstances}
                                 runFinancial={(instance: FeasibilityInstance) => setInstanceForFinancial(instance)}
                                 foursquareToken={foursquareToken}
                              />
                           </TabPanel>
                           <TabPanel value="2">
                              <FinancialsModels
                                 parentHeight={gridHeight}
                                 project={project}
                                 setProject={setProject}
                                 feasibilityInstances={feasibilityInstances}
                                 selectedInstance={instanceForFinancial}
                                 setSelectedInstance={setInstanceForFinancial}
                                 financialModels={financialModels}
                                 setFinancialModels={setFinancialModels}
                                 foursquareToken={foursquareToken}
                                 setFoursquareToken={setFoursquareToken}
                              />
                           </TabPanel>
                           <TabPanel
                              value="3"
                              style={{
                                 height: gridInfoHeight,
                                 maxHeight: gridInfoHeight,
                                 overflowY: 'auto',
                              }}>
                              <form onSubmit={updateProject} onChange={() => setHasChanged(true)} className="p-3">
                                 <div className="flex column between pt-3 pb-5">
                                    <div className="flex between gap mb-5">
                                       <div className="flex between column flex gap w-100 ">
                                          <TextField
                                             fullWidth
                                             label="Name"
                                             type={'text'}
                                             variant="outlined"
                                             value={project.name}
                                             onChange={(event: any) =>
                                                setProject({
                                                   ...project,
                                                   name: event.target.value,
                                                })
                                             }
                                             error={project.name.length === 0}
                                             helperText={project.name?.length === 0 ? 'Please provide a valid project name.' : ''}
                                             required={true}
                                          />
                                          {/* <Tooltip
                                             title={`${project.projectMeta.findIndex((meta: ProjectMeta) => meta.key === 'map_id') === -1 || (project.projectMeta.find((meta: ProjectMeta) => meta.key === 'map_id') ?? { value: '' }).value.length === 0 ? 'Provide a map ID to make it visible' : ''}`}
                                             placement="left"> */}
                                          <FormControl fullWidth>
                                             <InputLabel id="roles-label">Visibility</InputLabel>
                                             <Select
                                                labelId="roles-label"
                                                value={project.isActive ? 'true' : 'false'}
                                                label="Visibility"
                                                onChange={(event: any, val: any) => {
                                                   setHasChanged(true);
                                                   setProject({
                                                      ...project,
                                                      isActive: val.props.value == 'true',
                                                   });
                                                }}>
                                                {/* <MenuItem disabled={project.projectMeta.findIndex((meta: ProjectMeta) => meta.key === 'map_id') === -1 || (project.projectMeta.find((meta: ProjectMeta) => meta.key === 'map_id') ?? { value: '' }).value.length === 0} value={'true'}>
                                                   Public
                                                </MenuItem> */}
                                                <MenuItem value={'true'}>Public</MenuItem>
                                                <MenuItem value={'false'}>Admin Only</MenuItem>
                                             </Select>
                                          </FormControl>
                                          {/* </Tooltip> */}
                                          <FormControl fullWidth>
                                             <InputLabel id="roles-label">Status</InputLabel>
                                             <Select
                                                labelId="roles-label"
                                                value={project.status ?? PROJECT_STATUS.INIT.value}
                                                label="Visibility"
                                                onChange={(event: any, val: any) => {
                                                   setHasChanged(true);
                                                   setProject({
                                                      ...project,
                                                      status: val.props.value,
                                                   });
                                                }}>
                                                <MenuItem value={PROJECT_STATUS.INIT.value}>{PROJECT_STATUS.INIT.label}</MenuItem>
                                                <MenuItem value={PROJECT_STATUS.PROGRESS.value}>{PROJECT_STATUS.PROGRESS.label}</MenuItem>
                                                <MenuItem value={PROJECT_STATUS.REVIEW.value}>{PROJECT_STATUS.REVIEW.label}</MenuItem>
                                                <MenuItem value={PROJECT_STATUS.DONE.value}>{PROJECT_STATUS.DONE.label}</MenuItem>
                                             </Select>
                                          </FormControl>
                                          {/* <FormControl fullWidth>
                                             <InputLabel id="roles-label">Show Map Only</InputLabel>
                                             <Select
                                                labelId="roles-label"
                                                value={project.mapOnly ? 'true' : 'false'}
                                                label="Show Map Only"
                                                onChange={(event: any, val: any) => {
                                                   setHasChanged(true);
                                                   setProject({
                                                      ...project,
                                                      mapOnly: val.props.value == 'true',
                                                   });
                                                }}>
                                                <MenuItem value={'true'}>True</MenuItem>
                                                <MenuItem value={'false'}>False</MenuItem>
                                             </Select>
                                          </FormControl> */}
                                          <FormControl fullWidth>
                                             <InputLabel id="account-label" className="label">
                                                Accounts
                                             </InputLabel>
                                             <Select
                                                labelId="account-label"
                                                defaultOpen={false}
                                                value={project.accountProjects}
                                                label="Accounts"
                                                multiple
                                                renderValue={(selected: AccountProject[]) => selected.map((accountProject: AccountProject) => accountProject?.account?.name).join(', ')}
                                                onChange={(event: any, val: any) => {
                                                   setHasChanged(true);
                                                   const changedAccountId = val.props.value;
                                                   const exists = project.accountProjects.map((accountProject: AccountProject) => accountProject.accountId).includes(changedAccountId);
                                                   if (!exists) {
                                                      const toAdd = accounts.filter((account: Account) => account.id === changedAccountId)[0];
                                                      setProject({
                                                         ...project,
                                                         accountProjects: [
                                                            ...project.accountProjects,
                                                            {
                                                               accountId: toAdd.id,
                                                               isPaid: false,
                                                               account: {
                                                                  id: toAdd.id,
                                                                  name: toAdd.name,
                                                               },
                                                            } as AccountProject,
                                                         ],
                                                      });
                                                   } else {
                                                      if (project.accountProjects.length === 1 && project.accountProjects[0].accountId === changedAccountId) return;
                                                      setProject({
                                                         ...project,
                                                         accountProjects: project.accountProjects.filter((accountProject: AccountProject) => accountProject.accountId !== changedAccountId),
                                                      });
                                                   }
                                                }}>
                                                {accounts
                                                   .filter((account: Account) => account.name.length > 0)
                                                   .map((account: Account, index: number) => (
                                                      <MenuItem key={index} value={account.id}>
                                                         <Checkbox checked={project.accountProjects.map((accountProject: AccountProject) => accountProject.accountId).indexOf(account.id) > -1} />
                                                         <ListItemText primary={account.name} />
                                                      </MenuItem>
                                                   ))}
                                             </Select>
                                          </FormControl>
                                       </div>
                                       <div
                                          style={{
                                             maxWidth: '30%',
                                             width: '100%',
                                             backgroundImage: `url(${preview?.toString() ?? project.thumbnail ?? ''})`,
                                          }}
                                          className="p-2 update-thumbnail flex center items-center">
                                          {!project.thumbnail && !preview && <p className="no-thumbnail">No Thumbnail</p>}
                                          {/* <img style={{maxWidth: "100%"}} src={preview?.toString() ?? (project.thumbnail ?? "https://via.placeholder.com/500x400?text=Add+Thumbnail")} /> */}
                                          <div className="thumbnail-actions flex items-center gap-small">
                                             <Tooltip title={project.thumbnail || preview ? 'Update Thumbnail' : 'New Thumbnail'}>
                                                <IconButton aria-label="upload" color="primary" component="label">
                                                   <UploadIcon fontSize="large" />
                                                   <input type="file" accept="image/*" onChange={handleFileChange} hidden />
                                                </IconButton>
                                             </Tooltip>
                                          </div>
                                       </div>
                                    </div>
                                    {Object.keys(PROJECT_META_KEYS).map((key: string) => {
                                       const currentMeta: any = project.projectMeta.find((meta: ProjectMeta) => meta.key === key);
                                       if (key !== 'map_snapshots') {
                                          return (
                                             <TextField
                                                key={key}
                                                className="mt-3"
                                                label={key === 'map_id' ? 'Map ID' : metaKeyFriendly(key)}
                                                placeholder={metaKeyFriendly(key)}
                                                variant="outlined"
                                                multiline
                                                rows={key === 'description' ? 5 : 1}
                                                type={PROJECT_META_KEYS[key] === 'number' ? 'number' : 'text'}
                                                value={currentMeta?.value ?? ''}
                                                onChange={(event: any) => {
                                                   if (PROJECT_META_KEYS[key] == 'number' && isNaN(event.target.value)) return;
                                                   if (currentMeta === undefined) {
                                                      setProject({
                                                         ...project,
                                                         projectMeta: [
                                                            ...project.projectMeta,
                                                            {
                                                               key: key,
                                                               value: event.target.value.toString(),
                                                            },
                                                         ],
                                                      });
                                                   } else {
                                                      setProject({
                                                         ...project,
                                                         projectMeta: project.projectMeta.map((meta: ProjectMeta) =>
                                                            meta.key === currentMeta?.key
                                                               ? {
                                                                  ...meta,
                                                                  value: event.target.value.toString(),
                                                               }
                                                               : meta
                                                         ),
                                                      });
                                                   }
                                                }}
                                             />
                                          );
                                       }
                                    })}
                                 </div>
                                 <div className="static-save-div">
                                    <Button className="" variant="contained" type="submit" disabled={!hasChanged || !project.name.length}>
                                       SAVE
                                    </Button>
                                 </div>
                              </form>
                           </TabPanel>
                           <TabPanel value="4" sx={{ padding: 0 }}>
                              <ProjectAssets assets={project.assets} financialModels={financialModels} project={project} setProject={setProject} gridAssetsHeight={gridAssetsHeight} />
                           </TabPanel>
                           <TabPanel value="5">
                              <Logs logs={logs ?? []} gridHeight={gridAssetsHeight} includeUser />
                           </TabPanel>
                        </div>
                     )}
                  </TabContext>
               </Box>
            </div>
         )}
      </div>
   );
}
